import React from "react";
import { useOverrides } from "@quarkly/components";
import { Text, LinkBox, Link, Box, Section } from "@quarkly/widgets";
const defaultProps = {
	"padding": "80px 0 30px 0",
	"quarkly-title": "Footer-16",
	"background": "--color-darkL2"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"margin": "0px 0px 50px 0px",
			"md-margin": "0px 0px 45px 0px",
			"lg-flex-direction": "column"
		}
	},
	"linkBox": {
		"kind": "LinkBox",
		"props": {
			"width": "20%",
			"lg-margin": "0px 0px 35px 0px",
			"lg-width": "100%",
			"href": "#"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"color": "--light",
			"font": "--lead",
			"text-transform": "uppercase",
			"children": "Гольф-клуб Вітер"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"width": "80%",
			"lg-width": "100%",
			"justify-content": "flex-end",
			"align-items": "center",
			"sm-flex-direction": "column",
			"sm-align-items": "flex-start",
			"md-flex-direction": "column",
			"md-align-items": "flex-start",
			"md-display": "flex",
			"md-grid-gap": "24px"
		}
	},
	"box2": {
		"kind": "Box",
		"props": {
			"align-items": "flex-start",
			"margin": "0px 50px 0px 10px",
			"lg-align-items": "flex-start",
			"justify-content": "center",
			"display": "grid",
			"flex-direction": "row",
			"flex-wrap": "wrap",
			"md-justify-content": "flex-start",
			"sm-margin": "0px 0 30px 0",
			"grid-template-columns": "repeat(4, 1fr)",
			"grid-gap": "0 1rem",
			"sm-grid-template-columns": "1fr",
			"md-display": "flex",
			"md-grid-gap": "16px",
			"md-margin": "0px 50px 0px 0px",
			"text-transform": "uppercase"
		}
	},
	"link": {
		"kind": "Link",
		"props": {
			"border-color": "--color-primary",
			"display": "flex",
			"font": "normal 400 16px/24px --fontFamily-googleRoboto",
			"margin": "0px 0 0px 0",
			"lg-border-width": "0px 0px 0px 2px",
			"text-decoration-line": "initial",
			"color": "#c3c8d0",
			"hover-color": "--light",
			"letter-spacing": "1px",
			"md-margin": "0px 0 0px 0",
			"href": "/index",
			"children": "ГОЛОВНА"
		}
	},
	"link1": {
		"kind": "Link",
		"props": {
			"margin": "0px 0 0px 0",
			"display": "flex",
			"font": "normal 400 16px/24px --fontFamily-googleRoboto",
			"text-decoration-line": "initial",
			"color": "#c3c8d0",
			"hover-color": "--light",
			"letter-spacing": "1px",
			"md-margin": "0px 0 0px 0",
			"href": "/services",
			"children": "ПОСЛУГИ"
		}
	},
	"link2": {
		"kind": "Link",
		"props": {
			"margin": "0px 0 0px 0",
			"hover-color": "--light",
			"text-decoration-line": "initial",
			"color": "#c3c8d0",
			"font": "normal 400 16px/24px --fontFamily-googleRoboto",
			"letter-spacing": "1px",
			"md-margin": "0px 0 0px 0",
			"href": "/faq",
			"children": "faq",
			"display": "none"
		}
	},
	"link3": {
		"kind": "Link",
		"props": {
			"margin": "0px 0 0px 0",
			"hover-color": "--light",
			"font": "normal 400 16px/24px --fontFamily-googleRoboto",
			"text-decoration-line": "initial",
			"color": "#c3c8d0",
			"display": "flex",
			"letter-spacing": "1px",
			"md-margin": "0px 0 0px 0",
			"href": "/contacts",
			"children": "КОНТАКТИ"
		}
	},
	"box3": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"md-flex-direction": "column",
			"md-align-items": "flex-start",
			"justify-content": "space-between",
			"padding": "30px 0px 0px 0px",
			"border-width": "1px 0 0 0",
			"border-style": "solid",
			"border-color": "#2c3339",
			"lg-flex-direction": "column",
			"align-items": "flex-start",
			"width": "100%"
		}
	},
	"box4": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"display": "flex",
			"lg-margin": "0px 0px 20px 0px",
			"sm-flex-direction": "column",
			"margin": "0px 0px 0px auto"
		}
	},
	"box5": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"margin": "0px 50px 0px 0px",
			"display": "flex",
			"flex-direction": "column"
		}
	},
	"link4": {
		"kind": "Link",
		"props": {
			"color": "#c3c8d0",
			"text-decoration-line": "initial",
			"font": "normal 300 16px/1.5 --fontFamily-sans",
			"display": "inline-block",
			"text-align": "center",
			"margin": "0px 0px 5px 0px",
			"hover-color": "white",
			"href": "tel:0935082572",
			"children": "0679435247"
		}
	},
	"link5": {
		"kind": "Link",
		"props": {
			"color": "#c3c8d0",
			"text-decoration-line": "initial",
			"font": "normal 300 16px/1.5 --fontFamily-sans",
			"display": "inline-block",
			"margin": "0px 0px 15px 0px",
			"text-align": "center",
			"hover-color": "white",
			"href": "contact@nexalionpro.com",
			"children": "contact@nexalionpro.com"
		}
	},
	"box6": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 20px 0px",
			"color": "#c3c8d0",
			"font": "normal 300 16px/1.7 --fontFamily-sans",
			"border-color": "#b8acac",
			"md-margin": "0px 0px 15px 0px",
			"children": <>
				Obolonskyi Ave, 21Б,{" "}
				<br />
				Kyiv, 04205
			</>
		}
	}
};

const Header = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Box {...override("box")}>
			<LinkBox {...override("linkBox")}>
				<Text {...override("text")} />
			</LinkBox>
			<Box {...override("box1")}>
				<Box {...override("box2")}>
					<Link {...override("link")} />
					<Link {...override("link1")} />
					<Link {...override("link2")} />
					<Link {...override("link3")} />
				</Box>
			</Box>
		</Box>
		<Box {...override("box3")}>
			<Box {...override("box4")}>
				<Box {...override("box5")}>
					<Link {...override("link4")} />
					<Link {...override("link5")} />
				</Box>
				<Box {...override("box6")}>
					<Text {...override("text1")} />
				</Box>
			</Box>
		</Box>
		{children}
	</Section>;
};

Object.assign(Header, { ...Section,
	defaultProps,
	overrides
});
export default Header;